import React from 'react';

export const VisibilityIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 16">
    <g opacity=".7">
      <path
        fill="#a49382"
        d="M28.783 7.407C28.525 7.105 22.3 0 14.5 0S.475 7.105.217 7.407a.92.92 0 0 0 0 1.186C.475 8.895 6.7 16 14.5 16s14.025-7.105 14.283-7.407a.92.92 0 0 0 0-1.186z"
      />
      <path
        fill="#fff"
        d="M14.5 14a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6z"
        data-name="Path"
      />
      <path
        fill="#a49382"
        d="M15.542 6.5A2.485 2.485 0 0 1 16.6 4.47a4.151 4.151 0 1 0 2.231 4.4 2.5 2.5 0 0 1-3.289-2.37z"
        data-name="Path"
      />
    </g>
  </svg>
);