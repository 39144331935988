import React, { useRef, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper';
import { VisibilityIcon } from '../Layout/Icons/VisibilityIcon';
import Container from '../Layout/SharedStyles/Container';
import Button from '../Button/Button';
import style from './slider-products.mod.scss';
import './slider-products.css';
import { ArrowForwardIconThin } from '../Layout/Icons/ArrowForwardIconThin';
import IconWavesSlider from '../Layout/Icons/IconWavesSlider';

const SliderProducts = ({ slides }) => {
  const swiperRef = useRef(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  let swiperSettings = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      type: 'fraction',
    },
    slidesPerView: 1,
    watchOverflow: true,
    modules: [FreeMode, Navigation, Thumbs, Pagination],
  };

  return (
    <Container width="medium">
      <div className={style.slider_products__container}>
        <Swiper
          ref={swiperRef}
          spaceBetween={10}
          thumbs={{ swiper: thumbsSwiper }}
          {...swiperSettings}
        >
          {slides.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div className={style.slider_products}>
                <div className={style.slider_products__image}>
                  <GatsbyImage
                    image={slide.featureImage.gatsbyImageData}
                    alt={slide.featureImage.alt || ''}
                  />
                </div>
                <div className={style.slider_products__content}>
                  {slide.tagLine && (
                    <p className={style.slider_products__subHeading}>
                      {slide.tagLine}
                    </p>
                  )}
                  <h2 className={style.slider_products__heading}>
                    {slide.heading}
                  </h2>
                  <p
                    className={style.slider_products__description}
                    dangerouslySetInnerHTML={{ __html: slide.description }}
                  ></p>
                  <Button dark primary size={'large'} recordId={slide.id}>
                    Read More
                  </Button>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className={style.slider_products__sliderNav}>
            <div className={style.slider_products__buttons}>
              <CarouselPrev />
              <CarouselNext />
            </div>
          </div>
        </Swiper>
        <div className={style.slider_products__subSwiper}>
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
          >
            {slides.map((slide) => (
              <SwiperSlide key={slide}>
                <div className="mySwiper__subWrapper">
                  <div>
                    <GatsbyImage
                      image={slide.featureImage.gatsbyImageData}
                      alt={slide.featureImage.alt || ''}
                    />
                  </div>
                  <div className={style.slider_products__background_image}>
                    <IconWavesSlider />
                  </div>
                  <div className="mySwiper__content">
                    <label className="mySwiper__content__label">
                      {slide.heading}
                    </label>
                    <span className="mySwiper__content__icon">
                      <VisibilityIcon />
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Container>
  );
};

export default SliderProducts;

const CarouselPrev = () => (
  <div className={style.slider_products__buttons_prev}>
    <div aria-label="Previous" className="swiper-button-prev">
      <ArrowForwardIconThin />
    </div>
  </div>
);

const CarouselNext = () => (
  <div className={style.slider_products__buttons_next}>
    <div aria-label="Next" className="swiper-button-next">
      <ArrowForwardIconThin />
    </div>
  </div>
);
