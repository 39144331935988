import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import ModuleArea from '../components/ModularContent/ModuleArea';
import Hero from '../components/Hero/Hero';
import ProductCarousel from '../components/Product/ProductCarousel';
import Intro from '../components/Intro/Intro';

// import { Navigator } from '../components/LanguageHelpers/Navigator';

const HomepageTemplate = ({
  data: {
    datoCmsHomepage: {
      title,
      heroHeading,
      heroText,
      introText,
      introHeading,
      collections,
      heroLink,
      heroLinkText,
      heroImage,
      heroImageMobile,
      modularContent,
      homeHeading,
      homeContent,
      products,
      metaTags,
      heroLinks,
    },
  },
  pageContext,
}) => {
  return (
    <PageWrapper home pageData={pageContext} metaTags={metaTags}>
      <Hero
        heading={heroHeading}
        text={heroText}
        image={heroImage}
        mobileImage={heroImageMobile}
        links={heroLinks}
      />
      <Intro
        title={introHeading}
        text={introText}
        cards={collections}
      />
      <ProductCarousel
        heading={homeHeading}
        text={homeContent}
        products={products}
      />
      {modularContent.length > 0 && <ModuleArea blocks={modularContent} />}
    </PageWrapper>
  );
};

export default HomepageTemplate;

export const query = graphql`
  query HomepageQuery($locale: String!) {
    datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      homeHeading
      homeContent
      heroLinks {
        id
        page {
          ... on DatoCmsHomepage {
            id: originalId
          }
          ... on DatoCmsOtherPage {
            id: originalId
          }
          ... on DatoCmsCollection {
            id: originalId
          }
          ... on DatoCmsDealerPage {
            id: originalId
          }
          ... on DatoCmsContactPage {
            id: originalId
          }
        }
        text
      }
      metaTags {
        title
        description
        image {
          url
        }
      }
      products {
        heading: title
        id: originalId
        description
        tagLine
        featureImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "580"
              fit: "fill"
              fill: "solid"
              w: "800"
              q: 60
              fillColor: "00ffffff"
              auto: "format"
            }
          )
        }
      }
      heroImage {
        alt
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", h: "800", fit: "crop", w: "1440", q: 60 }
        )
      }
      heroImageMobile: heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", h: "800", fit: "crop", w: "400", q: 60 }
        )
      }
      heroHeading
      heroText
      introText
      introHeading
      collections {
        ... on DatoCmsCollection {
          cardImage {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { fm: "webp", h: "800", fit: "crop", w: "1440", q: 60 }
            )
          }
          cardTitle
          title
          id: originalId
        }
        ... on DatoCmsAllProduct {
          title
          id: originalId
          cardCtaText
        }
      }
      modularContent {
        ... on DatoCmsContentBlock {
          ...Content
        }
        ... on DatoCmsImageBlock {
          ...Image
        }
        ... on DatoCmsContentWithMediaBlock {
          ...ContentWithMedia
        }
        ... on DatoCmsSliderStandardWidthBlock {
          ...SliderStandardWidth
        }
        ... on DatoCmsFeatureBlock {
          ...Feature
        }
        ... on DatoCmsLeadBlock{
          ...LeadBlock
        }
        ... on DatoCmsPathwaysBlock {
          ...PathwaysBlock
        }
      }
    }
  }
`;
